'use client';
import * as React from 'react';
import { default as ControlledFooter } from '@wearemotivated/design-system/layouts/Footer/Footer';
import { Footer as StrapiFooter, LinkItem } from '@strapi-schema';
import { getMediaAbsoluteUrl } from '@/redesign/lib/utils/media';
import { LandingPageThemeType } from '@/redesign/types';
import { useSurveyEvent } from '@wearemotivated/wizard-client/components/SurveyEventContext';

type FooterProps = {
  data: StrapiFooter;
  className?: string;
  layout?: string;
  theme?: LandingPageThemeType;
};

const Footer = (props: FooterProps) => {
  const { data, className, layout, theme: propTheme } = props;
  const {
    components: { wizardDesign },
  } = useSurveyEvent();

  const theme = wizardDesign === 'new-wizard' ? 'clean' : propTheme;

  return (
    <ControlledFooter
      theme={theme}
      className={className}
      layout={wizardDesign === 'new-wizard' ? 'default' : layout}
      logo={{ alt: 'Motivated', url: '/logo.png' }}
      // @ts-expect-error
      bottomLinks={
        data.attributes?.bottomLinks?.items.map((i: LinkItem) => ({
          text: i.text ?? '',
          url: i.url,
        })) ?? []
      }
      // @ts-expect-error
      complianceIcons={
        data.attributes?.complianceIcons?.items.map((i: LinkItem) => ({
          text: i.text ?? '',
          url: i.url,
          iconUrl: i.iconUrl,
          icon: getMediaAbsoluteUrl(i.icon?.data.attributes.url) ?? undefined,
        })) ?? []
      }
      contactSupportButton={{
        // @ts-expect-error
        url: data.attributes?.contactSupportButton?.url,
        text: data.attributes?.contactSupportButton?.text ?? '',
      }}
      // @ts-expect-error
      learnMoreLinks={
        data.attributes?.learnMoreLinks?.items.map((i: LinkItem) => ({
          text: i.text ?? '',
          url: i.url,
        })) ?? []
      }
      // @ts-expect-error
      socialLinks={
        data.attributes?.socialsLinks?.items.map((i: LinkItem) => ({
          text: i.text ?? '',
          url: i.url,
          icon: getMediaAbsoluteUrl(i.icon?.data.attributes.url) ?? undefined,
        })) ?? []
      }
      popularTreatments={
        data.attributes?.popularTreatments.data.map((i: any) => ({
          name: i.attributes.name,
          url: i.attributes.url ?? null,
        })) ?? []
      }
      socialLinksTitle={data.attributes?.socialsLinks?.items ? 'Social' : ''}
      learnMoreTitle={data.attributes?.learnMoreLinksColumnTitle ?? ''}
      popularTreatmentsTitle={
        data.attributes?.popularTreatmentsColumnTitle ?? ''
      }
      copyrightsText={data.attributes?.copyrightsText ?? ''}
      missionStatement={data.attributes?.missionStatement ?? ''}
      supportColumnDescription={data.attributes?.supportColumnDescription ?? ''}
      supportColumnTitle={data.attributes?.supportColumnTitle ?? ''}
      disclaimerText={data.attributes?.disclaimerText}
    />
  );
};

export default Footer;
